import React from "react"
import { Flex, Box } from "theme-ui"
import Tile from "../tile"
import { Icon } from "../../v2/ui/Icon"
import { NewsletterDescription } from "."
import { NewsletterSignupField } from "."
import { contentfulSettings } from "../../../utils/contentful-settings"
import { useTranslation } from "../../../hooks/v2/translate"

const NewsletterSignup = ({ onComplete, handleClose }) => {
  const translate = useTranslation()

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 999999,
        right: 0,
        top: ["unset", "initial"],
        left: [0, "initial"],
        bottom: 0,
        margin: ["16px 16px 10px", "16px"],
      }}
    >
      <Flex
        sx={{
          flex: 1,
          bg: "grayscale.white",
          color: "primary",
          filter: "drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.08))",
          flexDirection: ["column", "row"],
          position: "relative",
          minHeight: ["auto", "308px"],
        }}
      >
        <Flex
          sx={{
            color: "grayscale.700",
            position: "absolute",
            right: 0,
            zIndex: 2,
            margin: ["16px", "16px"],
            justifyContent: "flex-end",
            svg: {
              width: [8, 5],
              height: [8, 5],
              cursor: "pointer",
              bg: ["grayscale.white", "none"],
              borderRadius: ["50%", "none"],
              padding: ["5px", "0px"],
            },
          }}
        >
          <Icon name="x" onClick={handleClose} />
        </Flex>
        {contentfulSettings.newsletterImage && (
          <Flex
            sx={{
              flex: 2,
              maxHeight: ["40vh", "400px"],
              width: ["auto", "215px"],
              overflow: "hidden",
              ".tile-image-container": { flex: 1 },
            }}
          >
            <Tile
              input={{
                backgroundImageUrl: contentfulSettings.newsletterImage,
                hideTitle: true,
              }}
              showProductTitle={false}
            />
          </Flex>
        )}
        <Flex
          sx={{
            flex: 3,
            bg: "grayscale.white",
            zIndex: 1,
            maxWidth: ["unset", "320px"],
            flexDirection: "column",
            padding: ["0 16px 16px", "16px"],
            justifyContent: "center",
          }}
        >
          <Flex sx={{ flexDirection: "column", padding: "8px" }}>
            <NewsletterDescription
              title={contentfulSettings.newsletterTitle}
              subtitle={translate(
                "Sign up to our newsletter and receive a 10% discount on your next order. Offer not valid on Archive products."
              )}
            />

            <NewsletterSignupField
              onComplete={onComplete}
              consentText={contentfulSettings.newsletterConsent}
              source="archive_popup"
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default NewsletterSignup
